var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-step", {
    attrs: {
      stepperGrpCd: "RAM_RISK_STEP_CD",
      currentStepCd: _vm.popupParam.ramStepCd,
      param: _vm.param,
    },
    on: {
      stepBystep: _vm.stepBystep,
      keySetting: _vm.keySetting,
      research: _vm.research,
      stepClick: _vm.stepClick,
      closePopup: _vm.closePopup,
    },
    model: {
      value: _vm.ramStepCd,
      callback: function ($$v) {
        _vm.ramStepCd = $$v
      },
      expression: "ramStepCd",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }